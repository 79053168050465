import './App.css';
import NavBar from './components/NavBar';
import React from 'react';
import { Box } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import SecureRoute from './components/SecureRoute';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Main from './Dashboard/Main';
import AppBar from '@mui/material/AppBar';
import Footer from './components/Footer';


const oktaAuth = new OktaAuth({
  issuer: 'https://uatamd.oktapreview.com/oauth2/default',
  clientId: '0oah8yjfq1sJ3JF1Z1d7',
  redirectUri: window.location.origin + '/authorization-code/callback',
  scopes: ['profile', 'openid', 'groups', 'email']
});

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  // Styling
  const HEADER_HEIGHT = 90;

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh', 
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 'none',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
            backgroundColor: 'transparent',
            height: HEADER_HEIGHT,
            width: '100%',
          }}
        >
          <NavBar />
        </AppBar>
        <Box
          component="main"
          sx={{
            flexGrow: 1, 
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Routes>
            <Route path="/authorization-code/callback" element={<LoginCallback />} />
            <Route element={<SecureRoute />}>
              <Route path="/" element={<Main />} />
            </Route>
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Security>
  );
}

export default App;
