import React, { useState, useEffect } from 'react'
import { Modal, Link, Button, Typography, IconButton, Box, CircularProgress, Stack, Popover, TextField } from '@mui/material';
import BarcodeSearch from './BarcodeSearch';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import DownloadComponent from './DownloadComponent';
import { useOktaAuth } from '@okta/okta-react';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const WhiteButton = styled(Button)(({ theme, disabled }) => ({
    backgroundColor: disabled ? '#eeeeee' : theme.palette.common.white,
    color: disabled ? theme.palette.common.white : "black",
    '&:hover': {
        backgroundColor: disabled ? theme.palette.grey[300] : '#63b1e5',
    },
    borderRadius: 25,
    width: '100%',
    fontSize: 'small', // Set the font size here
    textTransform: 'capitalize',
    fontWeight: 'bolder',
    textAlign: 'center'
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));


const SearchContent = ({ setSearchResult, setDownloadResult, ip }) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        console.log(authState)
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
        }
    }, [authState, oktaAuth]);

    const [barcodeImage, setBarcodeImage] = useState(null);
    const [bulkQueryExcel, setBulkQueryExcel] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [Excelloading, setExcelLoading] = useState(false);

    const [barcodeSerial, setbarcodeSerial] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [exampleImgAnchorEl, setExampleImgAnchorEl] = useState(null);
    const handleExpBarcodeClick = (event) => {
        setExampleImgAnchorEl(event.currentTarget);
    }
    const handleCloseExpBarcode = () => {
        setExampleImgAnchorEl(null);
    };

    const backend_base_url = process.env.REACT_APP_BACKEND_API_URL;

    const UploadBulkQueryExcel = async () => {
        setExcelLoading(true);
        //console.log(bulkQueryExcel)
        const formData = new FormData();
        formData.append('file', bulkQueryExcel, bulkQueryExcel.name);
        formData.append('userID', userInfo.preferred_username);
        formData.append('ip', ip);

        try {
            const response = await axios.post(`${backend_base_url}/api/STSProDataQuery/2d-pro-traceability-bulk-query`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error("Error posting data:", error);
            throw error;
        } finally {
            setExcelLoading(false);
        }

    }

    const UploadBarcodeImg = async (imgfile) => {
        setLoading(true);
        const imgFormData = new FormData();
        imgFormData.append('barcodeImg', imgfile, imgfile.name);
        imgFormData.append('userID', userInfo.preferred_username);
        imgFormData.append('ip', ip);

        try {
            const response = await axios.post(`${backend_base_url}/api/STSProDataQuery/2d-pro-traceability-barcodeImg`, imgFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response)
            if (response.status === 200) {
                setbarcodeSerial(response.data);
            } else {
                const message = response.data;
                showModal(message);
            };
        } catch (error) {
            console.error("Error posting data:", error);
            showModal("An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false);
        }

    }

    const showModal = (message) => {
        setModalMessage(message);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalMessage('');
    };

    const handleDeleteExcel = () => {
        setBulkQueryExcel(null)
    }

    const handleSubmitBulkQuery = async () => {
        await UploadBulkQueryExcel();
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setBulkQueryExcel(null);
    };

    const handleDownloadSearch = (downloadResult) => {
        setDownloadResult(downloadResult);
    };

    const handleSearch = (searchedJSONResult) => {
        setSearchResult(searchedJSONResult);
    };

    const BarcodeImageChange = (event) => {
        const imgfile = event.target.files[0];
        if (imgfile) {
            setBarcodeImage(imgfile);
            UploadBarcodeImg(imgfile)
        }
        console.log(imgfile)
    };

    const resetBarcodeSn = () => {
        setbarcodeSerial('');
    };

    const bulkQueryChange = (event) => {
        const xlsxfile = event.target.files[0];
        if (xlsxfile) {
            setBulkQueryExcel(xlsxfile);
        }
    };

    const handleDownload = () => {
        alert('Downloading uploaded file...');

        if (bulkQueryExcel) {
            const url = URL.createObjectURL(bulkQueryExcel);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', bulkQueryExcel.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                height: { xs: '310px', sm: '350px', md: '400px', lg: '440px', xl: '500px' },
                width: '100%',
                overflow: 'hidden',
                alignItems: 'center',
            }}
        >
            <Box
                component="img"
                src="/assets/mi300_img.png"
                alt="mi300 image"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: '-10',
                    minWidth: '100vw',
                    height: '100%',
                    objectFit: 'cover'
                }}
            />

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: '4%',
                    left: '4%',
                    width: { xs: '80%', md: '50%' },
                    zIndex: 1,
                    fontSize: { xs: '2rem', md: '2rem', lg: '2rem', xl: "2rem" },
                    lineHeight: '1.5',
                    textAlign: 'left'
                }}
            >
                Device Lookup
            </Typography>

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: { xs: '20%', md: '18%' },
                    left: '4%',
                    width: { sm: '80%', md: '70%', lg: '55%' },
                    zIndex: 1,
                    fontSize: { xs: '0rem', sm: '1rem', md: '1rem', lg: '1rem', xl: "1rem" },
                    lineHeight: '1.4',
                    marginTop: '0.5rem'
                }}
            >
                This tool is intended to provide general product information for MI series product, such as serial numbers, part number, etc.
            </Typography>

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: '28%',
                    left: '4%',
                    width: { sm: '80%', md: '70%', lg: '60%', xl: '50%' },
                    zIndex: 1,
                    fontSize: { xs: '0rem', md: '1rem', lg: '1rem', xl: "1rem" },
                    lineHeight: '1.4',
                    marginTop: '0.8rem'
                }}
            >
                Any unauthorized disclosure, use, dissemination, copying or the like of the information provided is prohibited.
            </Typography>

            <Typography
                color="white"
                sx={{
                    position: 'absolute',
                    top: '38%',
                    left: '4%',
                    width: { sm: '80%', md: '70%', lg: '60%' },
                    zIndex: 1,
                    fontSize: { xs: '0rem', md: '1rem', lg: '1rem', xl: "1rem" },
                    lineHeight: '1.4',
                    marginTop: '1rem',
                }}
            >
                Please always contact your AMD sales or FAEs for more detailed product information!
            </Typography>

            <BarcodeSearch
                onSearch={handleSearch}
                downloadSearch={handleDownloadSearch}
                barcodeSn={barcodeSerial}
                resetBarcodeSn={resetBarcodeSn}
                ip={ip}
            />

            <Box
                sx={{
                    position: 'absolute',
                    marginLeft: { xs: '29%', sm: '4%', md: '4%', lg: '4%', xl: '4%' },
                    width: { xs: '50%', md: '35%', lg: '25%', xl: '20%' },
                    textAlign: 'center',
                }}
            >
                {/* First row */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: { xs: '12rem', sm: '14rem', md: '16rem', lg: '18rem', xl: '20rem' }
                }}>
                    <WhiteButton
                        variant="contained"
                        component="label"
                        startIcon={<UploadFileIcon />}
                        sx={{
                            '& .MuiCircularProgress-svg': {
                                color: 'black',
                            },
                        }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Upload barcode image'}
                        <VisuallyHiddenInput
                            type="file"
                            onChange={BarcodeImageChange}
                            accept="image/*"
                        />
                    </WhiteButton>

                    <Modal
                        open={modalIsOpen}
                        onClose={closeModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={{ bgcolor: 'black', p: 4, borderRadius: 1, color: 'white', minWidth: 300 }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                {modalMessage}
                            </Typography>
                            <Button onClick={closeModal} sx={{ ml: 'auto', mt: 2 }} variant="contained" color="secondary">
                                Close
                            </Button>
                        </Box>
                    </Modal>

                    <LightTooltip title="Click to view sample barcode image" placement="top" TransitionComponent={Zoom}>
                        <IconButton
                            onClick={handleExpBarcodeClick}
                            sx={{
                                color: 'white',
                                '&:hover': {
                                    bgcolor: 'rgba(240, 240, 240, 0.1)',
                                    '& .MuiSvgIcon-root': {
                                        color: '#46d1ff',
                                    },
                                },
                            }}
                        >
                            <InfoIcon sx={{ fontSize: '1.5rem' }} />
                        </IconButton>
                    </LightTooltip>
                    <Popover
                        open={!!exampleImgAnchorEl}
                        anchorEl={exampleImgAnchorEl}
                        onClose={handleCloseExpBarcode}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={{ p: 2, bgcolor: 'black' }}>
                            <Typography variant="h6" sx={{ color: 'white' }}>Sample Barcode Image</Typography>
                            <img src="/assets/barcode_2d_example.PNG" alt="Sample Barcode" style={{ width: '100%', maxWidth: '400px' }} />
                        </Box>
                    </Popover>
                </Box>


                {/* Second row */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.8rem', lg: '1rem' }}>
                    <WhiteButton
                        variant="contained"
                        component="label"
                    >
                        Batch Query
                        <VisuallyHiddenInput type="file" onChange={bulkQueryChange} />
                    </WhiteButton>

                    <DownloadComponent />

                    {bulkQueryExcel && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Stack direction="row" alignItems="center">
                                <Link
                                    href="#"
                                    underline="always"
                                    onClick={handleDownload}
                                    component="button"
                                    sx={{
                                        color: '#FFFFFF',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {bulkQueryExcel.name}
                                </Link>
                                <IconButton
                                    size="small"
                                    onClick={handleDeleteExcel}
                                    sx={{
                                        color: '#FFFFFF',
                                        fontSize: 'small',
                                        padding: 0.2,
                                        '&:hover': {
                                            bgcolor: '#333333',
                                            '& svg': {
                                                color: 'red',
                                            },
                                        },
                                        '& svg': {
                                            color: '#FFFFFF',
                                        },
                                    }}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitBulkQuery}
                                    size="small"
                                    sx={{ padding: 0.3 }}
                                    disabled={Excelloading}
                                >
                                    {Excelloading ? <CircularProgress size={24} /> : 'Upload'}
                                </Button>
                            </Stack>
                        </Box>
                    )}
                    <Modal
                        open={modalOpen}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={{ bgcolor: 'black', p: 4, borderRadius: 1, color: 'white' }}>
                            <Typography variant="h6" component="h2">
                                File Uploaded Successfully
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                Your file has been uploaded and is being processed. <br />
                                Please check your email for the query result.
                            </Typography>
                            <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" color="secondary">
                                Close
                            </Button>
                        </Box>
                    </Modal>
                </Box>
            </Box>
        </Box>
    )
}

export default SearchContent