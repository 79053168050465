import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const SecureRoute = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(authState)
    console.log(oktaAuth)
    const checkAuth = async () => {
      if (authState === undefined) {
        // Authentication state is still being resolved
        return;
      }

      if (authState === null) {
        // Auth state is explicitly null
        setLoading(false);
        return <Navigate to="/" replace />;
      }

      if (!authState.isAuthenticated) {
        // Redirect to Okta login if not authenticated
        await oktaAuth.signInWithRedirect();
        return;
      }

      // Authentication successful
      setLoading(false);
    };

    checkAuth();
  }, [authState, oktaAuth]);

  // Show loading state while checking authentication
  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  // User is authenticated, render child components
  return <Outlet />;
};

export default SecureRoute;
