import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import AppBar from '@mui/material/AppBar';
import SearchContent from '../components/SearchContent';
import DownloadApp from '../components/DownloadApp';
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import CardDetails from '../components/CardDetails';
import axios from 'axios';

// Styling
const HEADER_HEIGHT = 90;

const BaseStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflowY: 'auto',
});

const ContentContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER_HEIGHT,
  paddingBottom: theme.spacing(0),
  position: 'relative',
}));


function Main() {
  const [searchResult, setSearchResult] = useState(null);
  const [downloadResult, setDownloadResult] = useState(null);
  const [ip, setIP] = useState("");

  const getIPAddress = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  useEffect(() => {
    getIPAddress();
  }, []);

  return (
    <div>
      <BaseStyle>
          <ContentContainer>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={8}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: HEADER_HEIGHT,
                    height: { xs: '300px', sm: '350px', md: '400px', lg: '440px', xl: '500px' },
                    width: '100%',
                    display: 'flex',
                    overflow: 'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SearchContent
                    setSearchResult={setSearchResult}
                    setDownloadResult={setDownloadResult}
                    ip={ip}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    position: 'relative',
                    zIndex: 1,
                    marginTop: { xs: '300px', sm: '350px', md: '400px', lg: '440px', xl: '500px' },

                  }}
                >
                  <CardDetails searchResult={searchResult} downloadResult={downloadResult} />
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    position: 'relative',
                    zIndex: 1,
                    marginTop: '100px'

                  }}
                >
                  <DownloadApp />
                </Box>
              </Grid>
            </Grid>
          </ContentContainer>

        
      </BaseStyle>
     
    </div>
  );
}

export default Main;
