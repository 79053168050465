import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Tooltip, Typography, Box, Stack, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from './SearchBar';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import { useOktaAuth } from '@okta/okta-react';


// Define constants for styling
const MOBILE_BAR = 64;
const DESKTOP_BAR = 92;
const HEADER_HEIGHT = 100;


// Styling root divs
const BaseStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: theme.palette.background.default,
    height: HEADER_HEIGHT,
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: MOBILE_BAR,
    [theme.breakpoints.up('lg')]: {
        minHeight: DESKTOP_BAR,
        padding: theme.spacing(0, 5),
    },

}));

const NavBar = () => {
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);


    useEffect(() => {
        console.log(authState)
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            //console.log(userInfo.email)
            // You can also get user information from the `/userinfo` endpoint
            /*oktaAuth.getUser().then((info) => {
              setUserInfo(info);
            });*/
        }
    }, [authState, oktaAuth]);

    const handleLogout = async () => {
        console.log('User logged out');
        handleClose();
        await oktaAuth.signOut({
            postLogoutRedirectUri: window.location.origin
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <BaseStyle>
            <ToolbarStyle>
                {isLargeScreen ? (
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems='center'
                        sx={{
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '5px',
                            paddingLeft: '20px',
                            paddingRight: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <img src="/assets/amd_logo.png" alt="AMD Logo" style={{ height: 40 }} />
                            <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: 60, marginTop: '15px', marginRight: '10px', marginLeft: '10px' }} />
                            <Typography variant="h2" color="white">MI300 Series Device Lookup</Typography>
                        </Box>
                        <SearchBar />

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton
                                    aria-describedby={id}
                                    sx={{ color: 'text.primary' }}
                                    onClick={handleClick}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{
                                    mt: '45px', '& .MuiPaper-root': {
                                        backgroundColor: '#424242',
                                        color: '#fff'
                                    }
                                }}
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>
                                    <Typography sx={{ textAlign: 'flex-start', fontFamily: '"Press Start 2P", monospace' }}>Account: <br /> <strong>{userInfo ? userInfo.name : 'Loading...'}</strong></Typography>
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <Typography sx={{ textAlign: 'center' }}>Logout</Typography>
                                    <IconButton size="small" color="inherit">
                                        <LogoutIcon />
                                    </IconButton>
                                </MenuItem>

                            </Menu>
                        </Box>


                    </Stack>
                ) : (
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems='center'
                        sx={{
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <img src="/assets/amd_logo.png" alt="AMD Logo" style={{ height: 20 }} />
                            <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: 30, marginTop: '15px', marginRight: '10px', marginLeft: '10px' }} />
                            <Typography variant="h3" color="white">MI300 Series Device Lookup</Typography>
                        </Box>


                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton
                                    aria-describedby={id}
                                    sx={{ color: 'text.primary' }}
                                    onClick={handleClick}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{
                                    mt: '45px', '& .MuiPaper-root': {
                                        backgroundColor: '#424242',
                                        color: '#fff'
                                    }
                                }}
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>
                                    <Typography sx={{ textAlign: 'flex-start', fontFamily: '"Press Start 2P", monospace' }}>Account: <br /> <strong>{userInfo ? userInfo.name : 'Loading...'}</strong></Typography>
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <Typography sx={{ textAlign: 'center' }}>Logout</Typography>
                                    <IconButton size="small" color="inherit">
                                        <LogoutIcon />
                                    </IconButton>
                                </MenuItem>

                            </Menu>
                        </Box>
                    </Stack>

                )}
            </ToolbarStyle>
        </BaseStyle>
    );
};

export default NavBar;
