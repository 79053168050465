import React from 'react'
import {
    Box,
    IconButton,
    Typography,
    Grid
} from "@mui/material";
import Link from '@mui/material/Link';

const DownloadApp = () => {

    return (
        <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
            {/* First Row */}
            <Box sx={{ paddingBottom: '5px' }}>
                <img src="/assets/ic_launcher_amd_ios.png" alt="2D pro mobile App" style={{ height: 60 }} />
            </Box>

            {/* Second Row */}
            <Typography variant="h4" sx={{ paddingBottom: '5px' }}>
                Download the mobile App here!
            </Typography>

            {/* Third Row */}
            <Grid container justifyContent="center" spacing={5} sx={{ paddingBottom: '5px', paddingLeft: '4%' }}>
                <Grid item>
                    <Link href="https://apps.apple.com/us/app/amd-device-lookup/id1063287962" underline="none">
                        <img src="/assets/AppleStore.svg" alt="Apple Store" style={{ width: '155%', height: 'auto' }} />
                    </Link>
                </Grid>
                <Grid item>
                    <Link href="https://play.google.com/store/apps/details?id=com.marketing.xilinxgo" underline="none">
                        <img src="/assets/GooglePlay.png" alt="Google Play Store" style={{ width: '75%', height: 'auto' }} />
                    </Link>
                </Grid>
            </Grid>

            {/* Fourth Row */}
            <Typography variant="h6">
                Note: iPad is not optimized at the moment. <br />For users without Google Play Store, download the APK {' '}
                <Link
                    href="https://www.amd.com/en/support/utilities/amd-device-lookup-support.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    color='#FF0000'
                    sx={{
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    here
                </Link>.
            </Typography>
        </Box>
    )
}

export default DownloadApp